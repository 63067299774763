<template>
  <div>
    <portal
      to="body-top"
      class="mb-2"
    >
      <h3 class="text-center font-weight-bolder mb-1">
        {{ $t(CREATE_PAGE_TITLE) }}
      </h3>
      <quote-status-bar :quote-status-bar="quoteStatusBar" />
    </portal>
    <common-form is-create-form />
  </div>
</template>

<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import CommonForm from '../components/CommonForm.vue'
import config from '../config'

export default {
  name: 'CreateForm',
  components: {
    QuoteStatusBar,
    CommonForm,
  },
  created() {
    this.onClear()
  },
  setup() {
    const {
      quoteStatusBar, MODULE_NAME, CREATE_PAGE_TITLE, onClear,
    } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
      CREATE_PAGE_TITLE,
      onClear,
    }
  },
}
</script>
